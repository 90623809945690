.react-quiz-container{

   

  align-self: center;
  margin: 0 auto;
 

  grid-column: 4/7;
  
  
}


.questionWrapper{
  margin: 0 auto;
  align-self: center;



}

.OuterQuiz{

  display: flex!important;
  align-self: center;
  margin: 0 auto;
  align-items: center;

}