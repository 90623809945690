.App {
  font-family: sans-serif;
  text-align: center;
}



.HomeContainer{

  display: flex;
  justify-content: center;
  margin: 20px;
  flex-wrap: wrap;
  display: grid;
  column-gap:  350px;
  row-gap: 20px;
}

.eachItem {
  display: inline-block;

  margin: 5%;
  
  


}
/* 
#box{
  grid-column:1 ;
  grid-template-rows: 300px auto 100px;



 }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#table{

  grid-column:1 / span 2;

  
  margin: auto;
  
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

  

} */

.poll-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.poll-container h3 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2rem;
}

.poll-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.poll-option-button {
  padding: 12px 16px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.poll-option-button:hover {
  background-color: #e3f2fd;
  border-color: #2196f3;
}
